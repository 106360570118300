import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Table } from 'react-bootstrap';
import SongListItem from './SongListItem';
import Release from './Release';

const ALL_ORIGINAL_RELEASES = gql`
query ALL_ORIGINAL_RELEASES {
  releases {
    nodes {
      count
      name
      description
      releaseId
      releaseData {
        artist
        date
        art {
          sourceUrl(size: THUMBNAIL)
        }
        sponsors {
          avatar {
            url
          }
          nickname
        }
        streamingServices {
          amazonMusic
          appleMusic
          spotify
          youtube
          youtubeCopy
        }
      }
      songs {
        nodes {
          songId
          title
          songData {
            artist
          }
          songFiles {
            flac {
              mediaItemUrl
            }
            mp3High {
              mediaItemUrl
            }
            mp3Low {
              mediaItemUrl
            }
            wav {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
  }

`


// This is the query that Apollo Client will send to the WP site.
const ALL_RELEASES = gql`
  query RECENT_PERKS {
    songs(last: 9999, where: {taxQuery: {taxArray: {taxonomy: RELEASE, operator: EXISTS}}}) {
     edges {
       node {
        id
        title
        releases {
          nodes {
            name
            releaseData {
              date
              art {
                sourceUrl(size: THUMBNAIL)
              }
            }
          }
        }
        songData {
          author
          artist
          lyrics
          parentSong {
            ... on Song {
              id
            }
          }
          streamingServices {
            amazonMusic
            appleMusic
            fieldGroupName
            spotify
            youtube
            youtubeMusic
          }
          type
          video {
            mediaItemUrl
            mediaType
          }
          writtenOn
        }
        songFiles {
          flac {
            mediaItemUrl
          }
          mp3High {
            mediaItemUrl
          }
          mp3Low {
            mediaItemUrl
          }
          wav {
            mediaItemUrl
          }
        }
      }
    }
  }
}
`;

export function ReleasesList() {
  const { loading, error, data } = useQuery(ALL_ORIGINAL_RELEASES);
  if (loading) return <p>Firing all cylinders...</p>;
  if (error) return <p>There was an error retrieving releases.</p>;
  if (!data.releases.nodes.length) return <p>No releases available.</p>;
  let releases = [];
  data.releases.nodes.map(post => {
    const { releaseId } = post;
    releases.push(<Release key={releaseId} release={post} />);
    return true;
  });
  return(
    <>
      {releases.map(r=>(
        <>{r}</>
      ))}
    </>
  )
}

export function ReleasesTable() {
  const { loading, error, data } = useQuery(ALL_RELEASES);
  if (loading) return <p>Firing all cylinders...</p>;
  if (error) return <p>There was an error retrieving songs.</p>;
  if (!data.songs.edges.length) return <p>No songs available.</p>;
  let posts = [];
  data.songs.edges.map(edge => {
    const { node: post } = edge;
    const { songId } = post;
    posts.push(<SongListItem key={songId} post={post} />);
    return true;
  });
  return (
    <Table responsive>
      <thead>
        <tr>
          <th className="col-md-4">Title</th>
          <th>Release</th>
          <th>Songwriters</th>
          <th>Written</th>
          <th>Released</th>
        </tr>
      </thead>
      <tbody className="align-middle">
        {posts.map(p=>(
          <>{p}</>
        ))}
      </tbody>
    </Table>
  );
}
