import React from "react";
import { NavLink, Switch, Route, useLocation } from "react-router-dom";
import { ReleasesList } from '../content/Songs';
import { Nav } from 'react-bootstrap';

function Music() {
  const { pathname } = useLocation();
  return(
    <>
      <h1>Music</h1>
      <p>Releases and Playlists as tabs? Don't forget to add "Currently Playing" for active songs! Clicking play from a specific release or playlist should queue up THAT release or playlist. Play should be an option at the top of each release and playlist.</p>
      <p>Songs should link to their individual page with lyrics and any other information, videos, etc.</p>
      <Nav variant="tabs" defaultActiveKey="/music/discography">
        <Nav.Item>
          <NavLink
            to="/music/discography"
            className="nav-link"
            isActive={() => ['/music', '/music/discography'].includes(pathname)}>
              Discography
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/music/playlists" className="nav-link">Playlists</NavLink>
        </Nav.Item>
      </Nav>
      <Switch>
        <Route path="/music/playlists"><p>Playlists don't need as much information. Album art, song title, artist, track length.</p></Route>
        <Route path="/music/discography"><ReleasesList /></Route>
        <Route path="/music"><ReleasesList /></Route>
      </Switch>
    </>
  )
}

export default Music;
