import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBackward,
  faForward,
  faPlay,
  faRetweet,
  faRandom,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons'

// https://css-tricks.com/lets-create-a-custom-audio-player/

function Player() {
  // TODO: Push data to Player through props based on things clicked in the music section of the site.
  const songs = [
    {
      title: 'Holding My Hand',
      artist: 'Anne Dorko',
      art: 'https://cdn.dorko.tv/wp-content/uploads/2021/09/Holding-My-Hand-Cover-150x150.jpg',
      src: 'https://cdn.dorko.tv/wp-content/uploads/2021/08/Holding-My-Hand-Distributed.mp3',
      length: '2:18',
      type: 'audio/mp3'
    }
  ]
  const current = 0;
  const song = songs[ current ];
  return(
    <div id="player">
    <Row>
      <Col lg={3}>
        <Row>
          <Col xs={2}>
          <img src={song.art} className="img-fluid min-40" alt="" />
          </Col>
          <Col className="d-flex align-items-center">
            <p><strong>{song.title}</strong><br />
            <small>{song.artist}</small></p>
            <audio>
            <source src={song.src} />
            </audio>
          </Col>
        </Row>
      </Col>
      <Col lg={2} className="d-flex align-items-center flex-wrap">
      <div className="controls d-flex align-items-center">
        <div className="small back">
          <FontAwesomeIcon icon={faRandom} />
          <FontAwesomeIcon icon={faBackward} />
        </div>
        <div className="fs-5 play-pause mx-3">
          <FontAwesomeIcon icon={faPlay} />
        </div>
        <div className="small forward">
          <FontAwesomeIcon icon={faForward} />
          <FontAwesomeIcon icon={faRetweet} />
        </div>
      </div>
      </Col>
      <Col>
      <div className="w-100 h-100 d-flex timeline align-items-center justify-content-between">
        <div class="time" id="currentTime">0:00</div>
        <input type="range" id="playerTimeline" max="100" value="0" />
        <div class="time" id="songDuration">{song.length}</div>
        <FontAwesomeIcon icon={faVolumeUp} />
      </div>
      </Col>
      <Col lg={2}>
        <div className="w-100 h-100 d-flex playlist align-items-center justify-content-end">
          <small className="mx-2 text-muted">Playlist</small>
          <select>
            <option>Solo Releases</option>
            <option>Prereleases</option>
            <option>Unreleased</option>
            <option>Collaborations</option>
            <option>Acoustic</option>
          </select>
        </div>
      </Col>
    </Row>
    </div>
  )
}

export default Player;
