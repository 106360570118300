import React from "react";
import PerksList from '../content/Perks';

const Perks = () => (
  <>
    <h1>Perks</h1>
    <PerksList />
  </>
)

export default Perks;
