import { Route, Switch, Link, NavLink } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faMusic,
  faArrowAltCircleDown,
  faSignInAlt,
  faSignOutAlt,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons'
import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap';
import * as Pages from './components/pages/Pages';
import Player from './components/parts/Player';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const App = (props) => (
  <ApolloProvider client={props.client}>
    <div className="app">
      <Navbar id="header" bg="dark" variant="dark">
        <Container>
          <Link to="/" className="navbar-brand">
            <img src="/dorkotv-white-icon.png"
              width="45"
              height="45"
              className="d-inline-block align-top"
              alt="Dorko.tv logo"
              title="Dorko.tv" />
          </Link>
          <Nav className="fs-5">
            <NavLink to="/dashboard" className="nav-link" activeClassName="active"><FontAwesomeIcon icon={faHome} /></NavLink>
            <NavLink to="/music" className="nav-link"><FontAwesomeIcon icon={faMusic} /></NavLink>
            <NavLink to="/perks" className="nav-link"><FontAwesomeIcon icon={faArrowAltCircleDown} /></NavLink>
            <NavLink to="/account" className="nav-link"><FontAwesomeIcon icon={faUserCircle} /></NavLink>
            <NavLink to="/login" className="nav-link"><FontAwesomeIcon icon={faSignInAlt} /></NavLink>
            <NavLink to="/logout" className="nav-link"><FontAwesomeIcon icon={faSignOutAlt} /></NavLink>
          </Nav>
        </Container>
      </Navbar>
      <Container className="py-4">
        <Switch>
          <Route path="/login"><Pages.Login /></Route>
          <Route path="/dashboard"><Pages.Dashboard /></Route>
          <Route path="/music"><Pages.Music /></Route>
          <Route path="/perks"><Pages.Perks /></Route>
          <Route path="/account"><Pages.Account /></Route>
          <Route path="/">
            <h1>Dashboard or Login</h1>
            <a href="https://reactrouter.com/web/api/Redirect">Redirect based on login status</a>
          </Route>
        </Switch>
      </Container>
    </div>
    <footer className="bg-dark text-light">
      <Container>
        <Row>
          <Col>
            <Player />
          </Col>
        </Row>
      </Container>
    </footer>
  </ApolloProvider>
);

export default App;
