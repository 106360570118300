import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PerkItem from './Perk';
import { Row, Col } from 'react-bootstrap';

// This is the query that Apollo Client will send to the WP site.
const RECENT_PERKS = gql`
  query RECENT_PERKS {
    perks {
     edges {
       node {
         id
         title
         date
         featuredImage {
           node {
             altText
             sourceUrl
           }
         }
       }
     }
   }
  }
`;

function PerksList() {
  const { loading, error, data } = useQuery(RECENT_PERKS);
  if (loading) return <p>Firing all cylinders...</p>;
  if (error) return <p>There was an error retrieving perks.</p>;
  if (!data.perks.edges.length) return <p>No perks available.</p>;
  let posts = [];
  data.perks.edges.map(edge => {
    const { node: post } = edge;
    const { perkId } = post;
    posts.push(<PerkItem key={perkId} post={post} />);
    return true;
  });
  return (
    <Row xs={1} md={2}>
      {posts.map(p=>(
        <Col className="pb-4">{p}</Col>
      ))}
    </Row>
  );
}

export default PerksList;
