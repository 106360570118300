import React from 'react';
import { Card } from 'react-bootstrap';

// Helper function for formatting dates.
const formatDate = date => new Date( date ).toDateString();

const PerkItem = ({post}) => {
  const { perkId, title, date, featuredImage } = post;

  // Set featured image.
  let image = null;
  if ( featuredImage ) {
    const { sourceUrl: featuredImageUrl } = featuredImage.node;
    image = <Card.Img variant="top" src={featuredImageUrl} />
  }

  return(
    <Card key={perkId}>
      {image}
      <Card.Body>
        <Card.Title>{title}</Card.Title>
      </Card.Body>
      <Card.Footer>Posted {formatDate(date)}</Card.Footer>
    </Card>
  );


};

export default PerkItem;
