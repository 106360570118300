import React from "react";
import { Col, Form, Button, Card } from "react-bootstrap";

const LoginForm = (props) => {
  return (
    <Col className="mt-4">
      <Card>
        <Card.Body>
        <h2>Login</h2>
        <Card.Subtitle className="mb-2 text-muted">Perform the sacred knock.</Card.Subtitle>
        <Form autoComplete="false">
          <Form.Group className="mb-3">
            <Form.Text>Username</Form.Text>
            <Form.Control type="text" name="username" id="username"
                 placeholder="Username or email" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Text>Password</Form.Text>
            <Form.Control
              type="password"
              name="password"
              id="password"
              placeholder="Password" />
          </Form.Group>
          <Form.Group>
            <Button type="submit">Enter</Button>
          </Form.Group>
        </Form>
        </Card.Body>
      </Card>
    </Col>
  )
};

export default LoginForm;
