import React from 'react';
import { gql, useQuery } from '@apollo/client';
import BlogPost from './Post';
import { Row, Col } from 'react-bootstrap';

// This is the query that Apollo Client will send to the WP site.
const RECENT_POSTS = gql`
  query RECENT_POSTS {
    posts( where: {
      taxQuery: {
        relation: OR,
        taxArray: [
          {
            terms: ["global","private"],
            taxonomy: ACCESS,
            operator: IN,
            field: SLUG
          }
        ]
      }
    }) {
      edges {
        node {
          postId
          title
          date
          excerpt
          author {
            node {
              name
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

function PostList() {
  const { loading, error, data } = useQuery(RECENT_POSTS);
  if (loading) return <p>Firing all cylinders...</p>;
  if (error) return <p>There was an error retrieving posts.</p>;
  if (!data.posts.edges.length) return <p>No posts available.</p>;
  let posts = [];
  data.posts.edges.map(edge => {
    const { node: post } = edge;
    const { postId } = post;
    posts.push(<BlogPost key={postId} post={post} />);
    return true;
  });
  return (
    <Row xs={1} md={2}>
      {posts.map(p=>(
        <Col className="pb-4">{p}</Col>
      ))}
    </Row>
  );
}

export default PostList;
