import { Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock
} from '@fortawesome/free-solid-svg-icons'

function Release(props) {
  const { release } = props;
  const { releaseData: data } = release;
  return(
    <div key={props.key} className="my-4">
    <Row className="mt-4">
      <Col xs={4} lg={2}>
        <img src={data.art.sourceUrl} className="img-fluid min-50" alt="" />
      </Col>
      <Col>
        <h3>{release.name}</h3>
        <p>{data.artist} • {data.date} • {release.count} song{release.count > 1 ? 's' : null}</p>
        <p className="text-muted">{release.description}</p>
      </Col>
    </Row>
    <Row className="mb-4">
      <Col lg={{offset: 2}}>
        <Table className="w-100">
        <thead>
          <tr>
            <th className="text-center col-1">#</th>
            <th className="px-2 col-10">Title</th>
            <th className="text-center"><FontAwesomeIcon icon={ faClock } /></th>
          </tr>
        </thead>
        <tbody>
          {release.songs.nodes.map((song, index) => {
            const { songFiles: files } = song;
            let audio = null
            if ( null !== files.mp3Low ) {
              audio = files.mp3Low.mediaItemUrl
            } else if ( null !== files.mp3High ) {
              audio = files.mp3High.mediaItemUrl
            } else if ( null !== files.flac ) {
              audio = files.flac.mediaItemUrl
            } else if ( null !== files.wav ) {
              audio = files.wav.mediaItemUrl
            }
            return(
              <tr key={song.songId}>
                <td className="text-center col-md-1">{index+1}</td>
                <td className="py-1 px-2  lh-1">
                  {song.title}<br />
                  <small class="text-muted">{song.songData.artist}</small>
                </td>
                <td className="text-center">
                <audio id={"song_" + song.songId } preload="metadata" src={audio}></audio>
                  <span id={"song_" + song.songId + "_length"}>0:00</span>
                </td>
              </tr>
            );
          })}
        </tbody>
        </Table>
      </Col>
    </Row>
    </div>
  )
}

export default Release;
