import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

const client = new ApolloClient({
  uri: "https://hq.dorko.tv/graphql",
  cache: new InMemoryCache()
});

ReactDOM.render(
  <Router>
    <App client={client} />
  </Router>,
  document.getElementById('root')
);
