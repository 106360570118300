import React from 'react';
import { Card } from 'react-bootstrap';
import DOMPurify from 'dompurify';

// Helper function for formatting dates.
const formatDate = date => new Date( date ).toDateString();

const BlogPost = ({post}) => {
  const { postId, title, date, author, featuredImage, excerpt } = post;
  const { name: authorName } = author.node;

  // Set featured image.
  let image = null;
  if ( featuredImage ) {
    const { sourceUrl: featuredImageUrl } = featuredImage.node;
    image = <Card.Img variant="top" src={featuredImageUrl} />
  }

  return(
    <Card key={postId}>
      {image}
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        { <div className="excerpt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(excerpt) }} /> }
      </Card.Body>
      <Card.Footer>Posted {formatDate(date)} by {authorName}</Card.Footer>
    </Card>
  );


};

export default BlogPost;
